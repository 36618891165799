<template>
  <div class="container" style="position: relative;" >
	    <div @click="go_info(1)"  style="position:absolute;  top:50px; z-index: 100000; width: 100%; background-color:    ; height: 300px;"> </div>
 
	    <div @click="go_info(2)"  style="position:absolute;  top:400px;  z-index: 100000; width: 100%; background-color:   ; height: 300px;"> </div>
	   
		<div @click="go_info(3)"  style="position:absolute;  top:730px;  z-index: 100000; width: 100%; background-color:    ; height: 300px;"> </div>
		
		<div @click="go_info(4)"  style="position:absolute;  top:1050px;  z-index: 100000; width: 100%; background-color:   ; height: 300px;"> </div>
		  
		<el-image  :src="url"></el-image> 
		<!-- <div style="position:absolute;">444444</div> -->
		<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
  </div>
</template>

<script>
  export default {
    name: "news",
    data(id) {
      return {
        url: require('../../assets/images/news.jpg')
      }
    },
	methods: {
		go_info1(){
			this.$router.push({name: '付费新闻'})	
		},
		go_info(id){ 
			this.$router.push({name: '资讯详情'+id})	 
		}
	},
  }
</script>

<style scoped>
  .container {
    width: 1200px;
    margin: auto;
  }
</style>
